<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-7">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 medium">High & Lows</h5>
                        <div class="d-flex align-items-center position-relative directionMob">
                            <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                            <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu?'show':''">
                                <li class="pe-md-3"><a href="javascript:void(0)">D</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">W</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">M</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">Y</a></li>
                            </ul>
                            <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                <li class="border-btn pointer position-relative" @click="(selectGroup != 2) ? selectGroup = 2 : selectGroup = 0">
                                    <span class="name d-flex align-items-center justify-content-between">Forex <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                    <ul class="text-start dropdown_menu_animated" :class="selectGroup == 2?'show':''">
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-4 mb-md-5">
                        <div class="card-body box-shadow p-0 mx-0">
                            <div class="table-responsive">
                                <table class="table table-sm no-wrap">
                                    <thead>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>LTP</th>
                                            <th>HI</th>
                                            <th>HI to LTP</th>
                                            <th>Op to HI</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-5">
                        <div class="card-body box-shadow p-0 mx-0">
                            <div class="table-responsive">
                                <table class="table table-sm no-wrap">
                                    <thead>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>LTP</th>
                                            <th>HI</th>
                                            <th>HI to LTP</th>
                                            <th>Op to HI</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="d-flex align-items-center">
                                                    <img class="me-2" src="assets/images/market-flag1.png" alt="Icon" title="EURUSD" />
                                                    <span>EURUSD</span>
                                                </span>
                                            </td>
                                            <td>1.40940</td>
                                            <td>1.41944</td>
                                            <td>0.73%</td>
                                            <td>0.89%</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-5 mb-4 mb-xl-0">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="mb-0 medium">Performance</h5>
                    <div class="d-flex align-items-center position-relative directionMob">
                            <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                            <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu?'show':''">
                                <li class="pe-md-3"><a href="javascript:void(0)">D</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">W</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">M</a></li>
                                <li class="pe-md-3"><a href="javascript:void(0)">Y</a></li>
                            </ul>
                            <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                <li class="border-btn pointer position-relative" @click="(selectGroup != 2) ? selectGroup = 2 : selectGroup = 0">
                                    <span class="name d-flex align-items-center justify-content-between">Forex <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                    <ul class="text-start dropdown_menu_animated" :class="selectGroup == 2?'show':''">
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                </div>
                <img class="w-100" src="assets/images/chart/sentimentsChart1.svg" alt="Icon" title="Performance">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7 mb-4 mb-md-5">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h3 class="mb-0">Volatile Pairs</h3>
                    <ul class="toplinks d-flex align-items-center mb-0 mr-3">
                      <li><a href="#" class="active">All</a></li>
                      <li><a href="#">Forex</a> </li>
                      <li><a href="#">Crypto</a> </li>
                      <li class="noneHover p-0">
                        <a href="javascript:void(0)" class="dropdown-togglen d-flex" data-toggle="dropdown">
                            <vue-feather class="textcolor" type="chevron-down"></vue-feather>
                        </a>
                      <div class="dropdown-menu p-0">
                          <a class="dropdown-items" href="#">Currencies</a>
                          <a class="dropdown-items" href="#">Indices</a>
                        </div>
                      </li>
                    </ul>
                </div>
                <div class="card-body box-shadow p-0 mx-0">
                    <img class="w-100" src="assets/images/chart/sentimentsChart2.svg" alt="Icon" title="Performance">
                </div>
            </div>
            <div class="col-12 col-lg-5 mb-4 mb-lg-0">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h3 class="mb-0">Movement Range Hourly</h3>
                    <ul class="d-flex align-items-center mb-0 mr-3">
                        <li class="border-btn pointer position-relative" @click="(selectGroup != 3) ? selectGroup = 3 : selectGroup = 0">
                        <span class="name d-flex align-items-center justify-content-between">EURUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                            <ul class="text-start dropdown_menu_animated" :class="selectGroup == 3?'show':''">
                                <li><a href="javascript:void(0)">EURUSD</a></li>
                                <li><a href="javascript:void(0)">EURUSD</a></li>
                                <li><a href="javascript:void(0)">EURUSD</a></li>
                                <li><a href="javascript:void(0)">EURUSD</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="card-body box-shadow p-0 mx-0">
                    <img class="w-100" src="assets/images/chart/sentimentsChart4.svg" alt="Icon" title="Performance">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7 mb-4 mb-md-5">
                <div class="d-flex justify-content-between align-items-center border-bottom trade mb-4">
                    <ul class="toplinks borderRight pb-3 d-flex align-items-center mb-0">
                        <li class="ps-0"><a @click.prevent="taskTab = 'News';" :class="(taskTab == 'News')?'active':''" class="medium" href="javascript:void(0)">News</a></li>
                        <li class="ps-3"><a @click.prevent="taskTab = 'Ideas';" :class="(taskTab == 'Ideas')?'active':''" class="medium " href="javascript:void(0)">Ideas</a></li>
                    </ul>
                    <ul class="toplinks d-flex align-items-center mb-0 mr-3">
                        <li><a href="#" class="active">All</a></li>
                        <li><a href="#">Forex</a> </li>
                        <li><a href="#">Crypto</a> </li>
                        <li class="noneHover p-0">
                        <a href="javascript:void(0)" class="dropdown-togglen d-flex" data-toggle="dropdown">
                            <vue-feather class="textcolor" type="chevron-down"></vue-feather>
                        </a>
                        <div class="dropdown-menu p-0">
                            <a class="dropdown-items" href="#">Currencies</a>
                            <a class="dropdown-items" href="#">Indices</a>
                        </div>
                        </li>
                    </ul>
                </div>
                <div class="card-body box-shadow p-0 mx-0">
                    <img class="w-100 d-block" src="assets/images/chart/sentimentsChart3.svg" alt="Icon" title="Performance">
                </div>
            </div>
            <div class="col-12 col-lg-5 mb-4 mb-lg-0">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <h3 class="mb-0">Sentiments</h3>
                    <div class="d-flex align-items-center">
                        <ul class="toplinks d-flex align-items-center mb-0 mr-3">
                          <li><a href="#" class="active">All</a></li>
                          <li><a href="#">Forex</a> </li>
                          <li><a href="#">Crypto</a> </li>
                          <li class="noneHover p-0"><a href="javascript:void(0)" class="dropdown-togglen d-flex" data-toggle="dropdown">
                            <vue-feather class="textcolor" type="chevron-down"></vue-feather>
                        </a>
                          <div class="dropdown-menu p-0">
                              <a class="dropdown-items" href="#">Currencies</a>
                              <a class="dropdown-items" href="#">Indices</a>
                            </div>
                          </li>
                        </ul>
                    </div>
                </div>
                <div class="customProgressbar">
                    <ul class="barList position-relative">
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">BCHUSD </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 18%;" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter1.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">BCHUSD </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 18%;" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter1.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 82%;" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100">82%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">BCHUSD </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 18%;" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter1.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon"></span>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                            <span class="barPro w-100 d-flex align-items-center pe-3">
                                <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                  <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                </div>
                                <div class="progress w-50 border-radius-0 h-18">
                                  <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                </div>
                            </span>
                            <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon"></span>
                        </li>
                    </ul>
                </div>
              </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectGroup:0,
            taskTab:'News',
            dropdownMenu:false,
        }
    },    
}
</script>
