<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement bg-white innerPage-banner marketTrend pb-4">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Tools & Analysis</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Tools and Analysis </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch hideMobile">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="EURUSD" name="" /> 
                                        <a class="searchButton grd_btn" href="javascript:void(0)">Chart Patterns</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white tools_and_analysis">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between border-bottom trade mb-3">
                            <ul class="toplinks borderRight pb-3 d-flex align-items-center mb-0">
                                <li class="ps-0"><a @click.prevent="taskTab = 'Statistical';" :class="(taskTab == 'Statistical')?'active':''" class="medium" href="javascript:void(0)">Statistical</a></li>
                                <li class="ps-3"><a @click.prevent="taskTab = 'Sentiments';" :class="(taskTab == 'Sentiments')?'active':''" class="medium " href="javascript:void(0)">Sentiments</a></li>
                            </ul>
                            <div class="PublishIdea">
                                <router-link to="/publish-idea" class="grd_btn small">Publish Idea</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="showTabsList" v-if="taskTab == 'Statistical'">
            <section class="blockElement bg-white tools_and_analysis">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-xl-6 compareChart mb-4">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h5 class="mb-0">Risk & Performance</h5>
                                <div class="d-flex align-items-center position-relative">
                                    <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                        <li><a href="javascript:void(0)"><img width="12" src="assets/images/trade.svg" alt="Icon" title="Trade"></a></li>
                                        <li><a href="javascript:void(0)"><vue-feather size="15px" type="calendar"></vue-feather></a></li>
                                    </ul>
                                    <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">15M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                    <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu?'show':''">
                                        <li><a href="javascript:void(0)">15M</a></li>
                                        <li><a href="javascript:void(0)">30M</a></li>
                                        <li><a href="javascript:void(0)">1hr</a></li>
                                        <li><a href="javascript:void(0)">D</a></li>
                                        <li><a href="javascript:void(0)">W</a></li>
                                        <li><a href="javascript:void(0)">M</a></li>
                                        <li><a href="javascript:void(0)">Y</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body box-shadow mx-0">
                                <img src="assets/images/chart/analysisChart1.svg" alt="Icon" title="Risk & Performance">
                                <div class="selectGroup border-top trade pt-3 mt-2">
                                    <div class="d-flex align-items-center justify-content-between mobSerch flex-wrap mb-4">
                                        <div class="d-flex align-items-center">
                                            <h6 class="mb-0 me-2 f-15 medium">Select Group</h6>
                                            <ul class="marketList d-flex align-items-center mb-0">
                                                <li>
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag1.svg" class="rounded-circle active" alt="Flag" title=""></a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/country_flag/netherlands.webp" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag3.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/country_flag/netherlands.webp" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag3.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag4.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                            </ul>
                                            <div class="slideBtn d-flex align-items-center">
                                                <a href="javascript:void(0)" class="arrow d-flex"><vue-feather type="chevron-right"></vue-feather> </a>
                                            </div>
                                        </div>
                                        <div class="analysisSearch d-flex align-items-center">
                                            <div class="form-group inputNumber mb-0 me-2">
                                                <input type="number" class="form-control" placeholder="15" name="" /> 
                                            </div>
                                            <div class="expandMobile form-group position-relative inputIcon mb-0" :class="expandSearch?'active':''">
                                                <input type="text" class="form-control" placeholder="EURUSD" name="" /> 
                                                <a @click.prevent="expandSearch =!expandSearch" class="searchButton" href="javascript:void(0)"><vue-feather size="15px" type="search"></vue-feather></a>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="selectCurrency d-flex align-items-center justify-content-between flex-wrap">
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 1) ? selectGroup = 1 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 1?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 2)?selectGroup = 2 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 2?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 3)?selectGroup = 3 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 3?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 4)?selectGroup = 4 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 4?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 5) ? selectGroup = 5 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 5?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 6)?selectGroup = 6 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 6?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 7)?selectGroup = 7 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 7?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 8)?selectGroup = 8 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 8?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 compareChart mb-4">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h5 class="mb-0">Risk Analysis</h5>
                                <div class="d-flex align-items-center position-relative">
                                    <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 1) ? selectGroup = 1 : selectGroup = 0">
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="text-start dropdown_menu_animated" :class="selectGroup == 1?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <a @click.prevent="dropdownMenu1 = !dropdownMenu1" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">15M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                    <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu1?'show':''">
                                        <li><a href="javascript:void(0)">15M</a></li>
                                        <li><a href="javascript:void(0)">30M</a></li>
                                        <li><a href="javascript:void(0)">1hr</a></li>
                                        <li><a href="javascript:void(0)">D</a></li>
                                        <li><a href="javascript:void(0)">W</a></li>
                                        <li><a href="javascript:void(0)">M</a></li>
                                        <li><a href="javascript:void(0)">Y</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body box-shadow mx-0">
                                <img src="assets/images/chart/analysisChart2.svg" alt="Icon" title="Risk & Performance">
                                <div class="table-responsive border-top trade pt-3 mt-2">
                                    <table class="table table-borderless border-primary">
                                      <tr>
                                        <th class="f-15">EURUSD</th>
                                        <td class="green">15m</td>
                                        <td class="green">D</td>
                                        <td class="green">W</td>
                                        <td class="green">M</td>
                                        <td class="green">Y</td>
                                      </tr>
                                      <tr>
                                        <th>Last</th>
                                        <td>1.2144</td>
                                        <td>1.2144</td>
                                        <td class="green">1.2144</td>
                                        <td class="green">1.2144</td>
                                        <td>1.2144</td>
                                      </tr>
                                      <tr>
                                        <th>Median</th>
                                        <td>1.2146</td>
                                        <td>1.2146</td>
                                        <td>1.2146</td>
                                        <td>1.2146</td>
                                        <td class="green">1.2146</td>
                                      </tr>
                                      <tr>
                                        <th>Stdev</th>
                                        <td>2.25</td>
                                        <td class="red">2.25</td>
                                        <td>2.25</td>
                                        <td>2.25</td>
                                        <td>2.25</td>
                                      </tr>
                                      <tr>
                                        <th>Mean</th>
                                        <td class="red">1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                      <tr>
                                        <th>High</th>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                      <tr>
                                        <th>Low</th>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 compareChart mb-4">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h5 class="mb-0">Pivot Points</h5>
                                <div class="d-flex align-items-center position-relative">
                                    <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                        <li><a href="javascript:void(0)"><img width="12" src="assets/images/trade.svg" alt="Icon" title="Trade"></a></li>
                                        <li><a href="javascript:void(0)"><vue-feather size="15px" type="calendar"></vue-feather></a></li>
                                    </ul>
                                    <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">15M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                    <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu?'show':''">
                                        <li><a href="javascript:void(0)">15M</a></li>
                                        <li><a href="javascript:void(0)">30M</a></li>
                                        <li><a href="javascript:void(0)">1hr</a></li>
                                        <li><a href="javascript:void(0)">D</a></li>
                                        <li><a href="javascript:void(0)">W</a></li>
                                        <li><a href="javascript:void(0)">M</a></li>
                                        <li><a href="javascript:void(0)">Y</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body box-shadow mx-0">
                                <img src="assets/images/chart/analysisChart3.svg" alt="Icon" title="Risk & Performance">
                                <div class="selectGroup border-top trade pt-3 mt-2">
                                    <div class="d-flex align-items-center justify-content-between flex-wrap mobSerch mb-4">
                                        <div class="d-flex align-items-center">
                                            <h6 class="mb-0 me-2 f-15 medium">Select Group Method</h6>
                                            <ul class="marketList d-flex align-items-center mb-0">
                                                <li>
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag1.svg" class="rounded-circle active" alt="Flag" title=""></a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/country_flag/netherlands.webp" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag3.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/country_flag/netherlands.webp" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag3.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                                <li class="hideMobile">
                                                    <a href="javascript:void(0)"><img width="28" src="assets/images/flag4.svg" class="rounded-circle" alt="Flag" /></a>
                                                </li>
                                            </ul>
                                            <div class="slideBtn d-flex align-items-center">
                                                <a href="javascript:void(0)" class="arrow d-flex"><vue-feather type="chevron-right"></vue-feather> </a>
                                            </div>
                                        </div>
                                        <div class="analysisSearch d-flex align-items-center">
                                            <div class="form-group inputNumber mb-0 me-2">
                                                <input type="number" class="form-control" placeholder="15" name="" /> 
                                            </div>
                                            <div class="expandMobile form-group position-relative inputIcon mb-0" :class="expandSearch?'active':''">
                                                <input type="text" class="form-control" placeholder="EURUSD" name="" /> 
                                                <a @click.prevent="expandSearch =!expandSearch" class="searchButton" href="javascript:void(0)"><vue-feather size="15px" type="search"></vue-feather></a>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="selectCurrency d-flex align-items-center justify-content-between flex-wrap">
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 1) ? selectGroup = 1 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 1?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 2)?selectGroup = 2 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 2?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 3)?selectGroup = 3 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 3?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 4)?selectGroup = 4 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 4?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 5) ? selectGroup = 5 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 5?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 6)?selectGroup = 6 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 6?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 7)?selectGroup = 7 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 7?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                        <li class="border-btn pointer position-relative" @click="(selectGroup != 8)?selectGroup = 8 : selectGroup = 0">
                                            <label class="customCheckbox mb-0">
                                                <input type="checkbox" name="">
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                            <ul class="dropdown_menu_animated" :class="selectGroup == 8?'show':''">
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                                <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 compareChart">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <h5 class="mb-0">Risk Analysis</h5>
                                <div class="d-flex align-items-center position-relative">
                                    <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                         <li class="border-btn pointer position-relative" @click="(selectGroup != 1) ? selectGroup = 1 : selectGroup = 0">
                                        <span class="name d-flex align-items-center justify-content-between">GBPUSD <i class="ms-1 fa fa-caret-down" aria-hidden="true"></i></span>
                                        <ul class="text-start dropdown_menu_animated" :class="selectGroup == 1?'show':''">
                                            <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            <li><a href="javascript:void(0)">GBPUSD</a></li>
                                            <li><a href="javascript:void(0)">GBPUSD</a></li>
                                        </ul>
                                    </li>
                                    </ul>
                                    <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">15M <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                    <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0" :class="dropdownMenu?'show':''">
                                        <li><a href="javascript:void(0)">15M</a></li>
                                        <li><a href="javascript:void(0)">30M</a></li>
                                        <li><a href="javascript:void(0)">1hr</a></li>
                                        <li><a href="javascript:void(0)">D</a></li>
                                        <li><a href="javascript:void(0)">W</a></li>
                                        <li><a href="javascript:void(0)">M</a></li>
                                        <li><a href="javascript:void(0)">Y</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body box-shadow mx-0">
                                <img src="assets/images/chart/analysisChart4.svg" alt="Icon" title="Risk & Performance">
                                <div class="table-responsive border-top trade pt-3 mt-0">
                                    <table class="table table-borderless border-primary">
                                      <tr>
                                        <th class="f-15">EURUSD</th>
                                        <td class="green"> </td>
                                        <td class="green">D</td>
                                        <td class="green">W</td>
                                        <td class="green">M</td>
                                        <td class="green">Y</td>
                                      </tr>
                                      <tr>
                                        <th>1.2144</th>
                                        <td class="green">R3</td>
                                        <td>1.2144</td>
                                        <td class="green">1.2144</td>
                                        <td class="green">1.2144</td>
                                        <td>1.2144</td>
                                      </tr>
                                      <tr>
                                        <th class="green">+0.33%</th>
                                        <td class="green">R2</td>
                                        <td>1.2146</td>
                                        <td>1.2146</td>
                                        <td>1.2146</td>
                                        <td class="green">1.2146</td>
                                      </tr>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <td>P</td>
                                        <td class="red">2.25</td>
                                        <td>2.25</td>
                                        <td>2.25</td>
                                        <td>2.25</td>
                                      </tr>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <td class="green">S1</td>
                                        <td class="red">1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <td class="green">S2</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <td class="green">S1</td>
                                        <td class="red">1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <td class="green">S2</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                        <td>1.1205</td>
                                      </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement bg-white space">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-8 mb-lg-0 mb-5">
                            <ul class="toplinks visible borderRight pb-3 d-flex align-items-center border-bottom trade">
                                <li class="ps-0"><a class="medium active" href="javascript:void(0)">Forex</a></li>
                                <li class="ps-3"><a class="medium " href="javascript:void(0)">Crypto</a></li>
                                <li class="ps-3 hideMobile"><a class="medium " href="javascript:void(0)">Commodities</a></li>
                                <li class="ps-3 hideMobile"><a class="medium " href="javascript:void(0)">Indices</a></li>
                                <li class="ps-3">
                                    <div class="position-relative">
                                        <a @click="counttryTabs =!counttryTabs" href="javascript:void(0)" class="d-flex align-items-center">GBP Pairs <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                        <ul class="dropdown_menu_animated" :class="counttryTabs?'show':''">
                                            <li><a href="javascript:void(0)">Europe</a></li>
                                            <li><a href="javascript:void(0)">America</a></li>
                                            <li><a href="javascript:void(0)">Asia</a></li>
                                            <li><a href="javascript:void(0)">Africa</a></li>
                                            <li><a href="javascript:void(0)">Australia</a></li>
                                            <li><a href="javascript:void(0)">G20</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <div class="forxChart" :class="forxChart?'d-none':''">
                                <a @click="forxChart = !forxChart"  href="javascript:void(0)"><img src="assets/images/chart/analysisChart6.svg" alt="Icon" title="Correlation"></a>
                            </div>
                            <div class="elseShow card-body box-shadow forxChart" :class="forxChart?'':'d-none'">
                                <div class="text-end">
                                    <a @click="forxChart = !forxChart" href="javascript:void(0)" class="closePop d-inline-flex justify-content-end"><vue-feather size="18" type="x"></vue-feather></a>
                                </div>
                                <img src="assets/images/chart/popchart.svg" alt="Icon" title="Correlation">
                                <div class="d-flex align-items-center flex-wrap dataTable-responsive">
                                    <div class="inrollBlock">
                                        <h6 class="medium text-center">D</h6>
                                        <ul class="mb-0">
                                            <li>
                                                <span>Last</span>
                                                <span>73%</span>
                                            </li>
                                            <li>
                                                <span>Avarage</span>
                                                <span>68%</span>
                                            </li>
                                            <li>
                                                <span>High</span>
                                                <span>73%</span>
                                            </li>
                                            <li>
                                                <span>Low</span>
                                                <span>68%</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="inrollBlock">
                                        <h6 class="medium text-center">W</h6>
                                        <ul class="mb-0">
                                            <li>
                                                <span>Last</span>
                                                <span>73%</span>
                                            </li>
                                            <li>
                                                <span>Avarage</span>
                                                <span>68%</span>
                                            </li>
                                            <li>
                                                <span>High</span>
                                                <span>73%</span>
                                            </li>
                                            <li>
                                                <span>Low</span>
                                                <span>68%</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="inrollBlock">
                                    <h6 class="medium text-center">M</h6>
                                    <ul class="mb-0">
                                        <li>
                                            <span>Last</span>
                                            <span>73%</span>
                                        </li>
                                        <li>
                                            <span>Avarage</span>
                                            <span>68%</span>
                                        </li>
                                        <li>
                                            <span>High</span>
                                            <span>73%</span>
                                        </li>
                                        <li>
                                            <span>Low</span>
                                            <span>68%</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="inrollBlock">
                                    <h6 class="medium text-center">Y</h6>
                                    <ul class="mb-0">
                                        <li>
                                            <span>Last</span>
                                            <span>73%</span>
                                        </li>
                                        <li>
                                            <span>Avarage</span>
                                            <span>68%</span>
                                        </li>
                                        <li>
                                            <span>High</span>
                                            <span>73%</span>
                                        </li>
                                        <li>
                                            <span>Low</span>
                                            <span>68%</span>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="d-flex align-items-center justify-content-start border-bottom trade mb-4 exchageUSD">
                               <div class="position-relative mx-0 chartDropDowm">
                                    <a @click="counttryTabs2 =!counttryTabs2" href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated" :class="counttryTabs2?'show':''">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <div class="exchangeLibk mx-4">
                                    <img src="assets/images/exchange.svg" alt="Icon" title="Exchange">
                                </div>
                                <div class="position-relative mx-0 chartDropDowm">
                                    <a @click="counttryTabs3 =!counttryTabs3" href="javascript:void(0)" class="medium d-flex align-items-center">Forex <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated" :class="counttryTabs3?'show':''">
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body p-0 mb-3">
                                <div class="position-relative chartDropDowm">
                                    <a @click="counttryTabs4 =!counttryTabs4" href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated" :class="counttryTabs4?'show':''">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <img src="assets/images/chart/analysisChart5.svg" alt="Icon" title="Correlation">
                            </div>
                            <div class="card-body p-0 mb-3">
                                <div class="position-relative chartDropDowm">
                                    <a href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <img src="assets/images/chart/analysisChart5.svg" alt="Icon" title="Correlation">
                            </div>
                            <div class="card-body p-0 mb-3">
                                <div class="position-relative chartDropDowm">
                                    <a href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <img src="assets/images/chart/analysisChart5.svg" alt="Icon" title="Correlation">
                            </div>
                            <div class="card-body p-0 mb-3">
                                <div class="position-relative chartDropDowm">
                                    <a href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <img src="assets/images/chart/analysisChart5.svg" alt="Icon" title="Correlation">
                            </div>
                            <div class="card-body p-0">
                                <div class="position-relative chartDropDowm">
                                    <a href="javascript:void(0)" class="medium d-flex align-items-center">EURCAD <vue-feather size="18px" class="ms-1" type="chevron-down"></vue-feather></a>
                                    <ul class="dropdown_menu_animated">
                                        <li><a href="javascript:void(0)">Europe</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                        <li><a href="javascript:void(0)">EURCAD</a></li>
                                    </ul>
                                </div>
                                <img src="assets/images/chart/analysisChart5.svg" alt="Icon" title="Correlation">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="blockElement bg-white" v-if="taskTab == 'Sentiments'">
            <sentiments />
        </section>
        <section class="blockElement bg-white space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                        <h5 class="mb-4 medium">Trending Symbols</h5>
                        <ul class="trandingSymbols p-3 d-flex align-items-center flex-wrap">
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                            <li class="mb-0 pb-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                            <li class="mb-0 pb-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                            <li class="mb-0 pb-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>                                        
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green"> (+147.70)</p>                                        
                                </span>
                            </li>
                        </ul>
                    </div> 
                    <div class="col-12 col-xl-4">
                        <h5 class="mb-4 medium">Trending Search</h5>
                        <ul class="checkLabel-btn d-flex align-items-center flex-wrap">
                            <li>
                                <label class="customCheckbox" :class="trandingTabs?'active':''"><span class="d-flex align-items-center">Chart Patterns<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input @click="trandingTabs = !trandingTabs"  type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Economic Calendar <i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Fed Chairman <i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Bitcoin ban in India <i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Covid 3rd wave<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">BOE Stimulus<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Chart Patterns<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Economic Calendar<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox"><span class="d-flex align-items-center">Fed Chairman<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span>
                                    <input type="checkbox"  name="">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import sentiments from "./sentiments";
export default {
    data() {
        return {
            taskTab:'Statistical',
            trandingTabs:false,
            selectGroup: 0,
            counttryTabs: false,
            counttryTabs1: false,
            counttryTabs2: false,
            counttryTabs3: false,
            counttryTabs4: false,
            forxChart: false,
            dropdownMenu: false,
            dropdownMenu1: false,
            expandSearch: false,
        }
    },
    components: {
        bannerSlider:bannerslider,
        sentiments,

    },     
}
</script>
